.button-navbar:focus {
  outline: none !important;
  outline-offset: none !important;
  box-shadow: none !important;
  font-size: 14px;
  color: #ffffff;
  margin-left: 10px;
}

.button-navbar {
  outline: none !important;
  outline-offset: none !important;
  box-shadow: none !important;
  font-size: 14px;
  color: #ffffff;
  margin-left: 10px;
}

.nav-link {
  color: #ffffffbf !important;
  font-size: '18px';
  font-weight: '400';
  font-family: 'Roboto';
  transition: 0.2s;
}

.nav-link-arrow {
  position: relative;
  margin-right: 16px;
}

.nav-link-arrow svg {
  display: block;
  position: absolute;
  width: 18px;
  right: -15px;
  top: 12px;
}
.container-link.nav-link-arrow svg {
  top: 20px;
  right: -5px;
}

.nav-link:hover {
  color: #ffffff !important;
  text-decoration: underline;
}

.container-submenu {
  opacity: 0;
  transition: 0.3s;
  pointer-events: none;
  position: absolute;
  z-index: 10000;
  background-color: #000000bf;
  width: 250px;
  margin-top: 20px;
  margin-left: -20px;
  border-top: 2px solid #d94948;
}
.container-submenu::before {
  content: '';
  background-color: transparent;
  width: 100%;
  height: 20px;
  transform: translateY(-20px);
  position: absolute;
}
.container-submenu.submenu-item {
  margin-left: 242px;
  transform: translateY(-72px);
}
.container-submenu span {
  color: #ffffffbf;
  display: block;
  padding: 8px 12px;
  transition: 0.2s;
}

.container-submenu span:hover {
  color: #ffffff !important;
}

.container-submenu.active {
  opacity: 1;
  pointer-events: all;
}

#ourPublicationSubMenuItem,
#eventCampaignSubMenuItem {
  /* opacity: 0;
  pointer-events: none; */
  opacity: 0;
}

.menu-with-child:hover #ourPublicationSubMenuItem,
.menu-with-child:hover #eventCampaignSubMenuItem {
  opacity: 1;
}

.nav-link:hover .container-submenu,
.nav-link .container-submenu:hover {
  opacity: 1;
  pointer-events: all;
}

.nav-link:hover #ourPublication:hover .nav-link #eventCampaigns:hover #eventCampaignSubMenuItem,
.nav-link #ourPublication #ourPublicationSubMenuItem:hover {
  display: block;
}

ul {
  text-decoration: none;
  list-style-type: none;
}

.navbar {
  z-index: 1000;
}

.bg-nav-overlay {
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.bg-overlay {
  background: linear-gradient(
    rgb(0, 0, 0, 0.9),
    rgb(0, 0, 0, 0.5)
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    rgb(0, 0, 0, 0.9),
    rgb(0, 0, 0, 0.5)
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background-size: cover;
  background-repeat: no-repeat;
}

@media screen and (min-width: 1024px) {
  .nav-logo {
    width: 100px;
    height: 131px;
  }
  .Navbar-container {
    position: absolute;
    width: 100%;
  }
}

@media screen and (max-width: 1024px) {
  .nav-logo {
    width: 75px;
    height: 100px;
  }
  .navbar {
    background: #011627;
    padding: 5px 20px;
  }

  .navbar-toggler {
    margin-right: auto;
    position: relative;
    z-index: 100000;
    margin: 8px 20px;
  }
  .Navbar-container {
  }
}

@media screen and (max-width: 376px) {
}

@media screen and (max-width: 361px) {
}
