.title-content {
  font-family: Roboto, Arial, Helvetica, sans-serif;
  color: "#ffffff";
  font-size: 20px;
}

.title-content h1 ul li {
  font-family: Roboto, Arial, Helvetica, sans-serif;
  color: "#ffffff";
  font-size: 16px;
}

/* Large Screen */
@media screen and (min-width: 480px) {
  .content-footer {
    display: flex;
    justify-content: space-between;
  }
  .about-footer {
    width: 35%;
  }
  .content {
    padding: 100px 50px;
  }

  .copyright {
    display: flex;
    justify-content: space-between;
    margin: 10px 65px;
  }

  .border-footer {
    border: 0.5px solid #ffffffbf;
    width: 90%;
    margin: auto;
  }
}

/* Mobile Screen */
@media screen and (max-width: 425px) {
  .content-footer {
    display: block;
  }

  .content {
    padding: 25px 25px;
  }

  .copyright {
    display: flex;
    justify-content: space-around;
    margin-top: 10px;
  }

  .border-footer {
    border: 0.5px solid #ffffffbf;
    width: 90%;
    margin: auto;
  }
}

@media screen and (max-width: 376px) {
  .content-footer {
    display: block;
  }

  .content {
    padding: 25px 25px;
  }

  .copyright {
    display: flex;
    justify-content: space-around;
    margin-top: 10px;
  }

  .border-footer {
    border: 0.5px solid #ffffffbf;
    width: 90%;
    margin: auto;
  }
}

@media screen and (max-width: 361px) {
  .content-footer {
    display: block;
  }

  .content {
    padding: 25px 25px;
  }

  .copyright {
    display: flex;
    justify-content: space-around;
    margin-top: 10px;
  }

  .border-footer {
    border: 0.5px solid #ffffffbf;
    width: 90%;
    margin: auto;
  }
}
