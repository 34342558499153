.bg-nav-overlay {
  background: linear-gradient(
    rgb(0, 0, 0, 0.9),
    rgb(0, 0, 0, 0.5)
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    rgb(0, 0, 0, 0.9),
    rgb(0, 0, 0, 0.5)
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  opacity: 0.2;
  background-size: cover;
  background-repeat: no-repeat;
}

@media screen and (min-width: 480px) {
  .bg-nav-overlay {
    height: 265px;
  }
  .container-title-image {
    margin: 0;
    padding: 0;
  }

  .message {
    transform: translate(-5%, -200%);
    margin: auto;
    width: 700px;
  }

  .message h1 {
    font-size: 50px;
  }
}

/* Mobile screen */

@media screen and (max-width: 480px) {
  .container-title-image {
    margin: 0;
    padding: 0;
  }

  .message {
    transform: translate(-5%, -300%);
    margin: auto;
    width: 250px;
  }

  .message h1 {
    font-size: 32px;
  }
}

@media screen and (max-width: 376px) {
  .container-title-image {
    margin: 0;
    padding: 0;
  }

  .message {
    transform: translate(-5%, -300%);
    margin: auto;
    width: 250px;
  }

  .message h1 {
    font-size: 32px;
  }
}

@media screen and (max-width: 376px) {
  .container-title-image {
    margin: 0;
    padding: 0;
  }

  .message {
    transform: translate(-5%, -300%);
    margin: auto;
    width: 250px;
  }

  .message h1 {
    font-size: 32px;
  }
}
