@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap);
body {
  margin: 0;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.button-navbar:focus {
  outline: none !important;
  outline-offset: none !important;
  box-shadow: none !important;
  font-size: 14px;
  color: #ffffff;
  margin-left: 10px;
}

.button-navbar {
  outline: none !important;
  outline-offset: none !important;
  box-shadow: none !important;
  font-size: 14px;
  color: #ffffff;
  margin-left: 10px;
}

.nav-link {
  color: #ffffffbf !important;
  font-size: '18px';
  font-weight: '400';
  font-family: 'Roboto';
  transition: 0.2s;
}

.nav-link-arrow {
  position: relative;
  margin-right: 16px;
}

.nav-link-arrow svg {
  display: block;
  position: absolute;
  width: 18px;
  right: -15px;
  top: 12px;
}
.container-link.nav-link-arrow svg {
  top: 20px;
  right: -5px;
}

.nav-link:hover {
  color: #ffffff !important;
  text-decoration: underline;
}

.container-submenu {
  opacity: 0;
  transition: 0.3s;
  pointer-events: none;
  position: absolute;
  z-index: 10000;
  background-color: #000000bf;
  width: 250px;
  margin-top: 20px;
  margin-left: -20px;
  border-top: 2px solid #d94948;
}
.container-submenu::before {
  content: '';
  background-color: transparent;
  width: 100%;
  height: 20px;
  -webkit-transform: translateY(-20px);
          transform: translateY(-20px);
  position: absolute;
}
.container-submenu.submenu-item {
  margin-left: 242px;
  -webkit-transform: translateY(-72px);
          transform: translateY(-72px);
}
.container-submenu span {
  color: #ffffffbf;
  display: block;
  padding: 8px 12px;
  transition: 0.2s;
}

.container-submenu span:hover {
  color: #ffffff !important;
}

.container-submenu.active {
  opacity: 1;
  pointer-events: all;
}

#ourPublicationSubMenuItem,
#eventCampaignSubMenuItem {
  /* opacity: 0;
  pointer-events: none; */
  opacity: 0;
}

.menu-with-child:hover #ourPublicationSubMenuItem,
.menu-with-child:hover #eventCampaignSubMenuItem {
  opacity: 1;
}

.nav-link:hover .container-submenu,
.nav-link .container-submenu:hover {
  opacity: 1;
  pointer-events: all;
}

.nav-link:hover #ourPublication:hover .nav-link #eventCampaigns:hover #eventCampaignSubMenuItem,
.nav-link #ourPublication #ourPublicationSubMenuItem:hover {
  display: block;
}

ul {
  text-decoration: none;
  list-style-type: none;
}

.navbar {
  z-index: 1000;
}

.bg-nav-overlay {
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.bg-overlay {
  background: linear-gradient(
    rgb(0, 0, 0, 0.9),
    rgb(0, 0, 0, 0.5)
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    rgb(0, 0, 0, 0.9),
    rgb(0, 0, 0, 0.5)
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background-size: cover;
  background-repeat: no-repeat;
}

@media screen and (min-width: 1024px) {
  .nav-logo {
    width: 100px;
    height: 131px;
  }
  .Navbar-container {
    position: absolute;
    width: 100%;
  }
}

@media screen and (max-width: 1024px) {
  .nav-logo {
    width: 75px;
    height: 100px;
  }
  .navbar {
    background: #011627;
    padding: 5px 20px;
  }

  .navbar-toggler {
    margin-right: auto;
    position: relative;
    z-index: 100000;
    margin: 8px 20px;
  }
  .Navbar-container {
  }
}

@media screen and (max-width: 376px) {
}

@media screen and (max-width: 361px) {
}

.bg-nav-overlay {
  background: linear-gradient(
    rgb(0, 0, 0, 0.9),
    rgb(0, 0, 0, 0.5)
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    rgb(0, 0, 0, 0.9),
    rgb(0, 0, 0, 0.5)
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  opacity: 0.2;
  background-size: cover;
  background-repeat: no-repeat;
}

@media screen and (min-width: 480px) {
  .bg-nav-overlay {
    height: 265px;
  }
  .container-title-image {
    margin: 0;
    padding: 0;
  }

  .message {
    -webkit-transform: translate(-5%, -200%);
            transform: translate(-5%, -200%);
    margin: auto;
    width: 700px;
  }

  .message h1 {
    font-size: 50px;
  }
}

/* Mobile screen */

@media screen and (max-width: 480px) {
  .container-title-image {
    margin: 0;
    padding: 0;
  }

  .message {
    -webkit-transform: translate(-5%, -300%);
            transform: translate(-5%, -300%);
    margin: auto;
    width: 250px;
  }

  .message h1 {
    font-size: 32px;
  }
}

@media screen and (max-width: 376px) {
  .container-title-image {
    margin: 0;
    padding: 0;
  }

  .message {
    -webkit-transform: translate(-5%, -300%);
            transform: translate(-5%, -300%);
    margin: auto;
    width: 250px;
  }

  .message h1 {
    font-size: 32px;
  }
}

@media screen and (max-width: 376px) {
  .container-title-image {
    margin: 0;
    padding: 0;
  }

  .message {
    -webkit-transform: translate(-5%, -300%);
            transform: translate(-5%, -300%);
    margin: auto;
    width: 250px;
  }

  .message h1 {
    font-size: 32px;
  }
}

.button-copy {
  color: #2879d0;
}
.button-copy:hover,
.button-copy:active {
  color: #5eacff;
}

.modal-annual-report{
  width: 50vw;
  max-width: 50vw;
}

.frequency-container :focus {
  border-color: #84cfca;
}

.amount:hover {
  box-shadow: 0 2px 10px 0 rgb(0 0 0 / 25%);
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
  transition: 0.2s ease-in;
}

.amount:focus {
  box-shadow: 0 3px 3px 0 rgb(0 0 0 / 25%);
  transition: 0.2s ease-in;
}

.form-input:focus {
  border: 1px solid #ced4da;
}

.option-donate {
  font-family: "Roboto", Arial, Helvetica, sans-serif;
}

.dropdown {
  max-height: 150px;
  width: 100%;
  overflow-y: scroll;
}

.dropdown-no-match {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
}
/* Large Screen */
@media screen and (min-width: 480px) {
  .container-amount {
    margin: 30px 110px;
  }
  .amount {
    width: 85%;
    height: 120px;
    border: 1px solid rgb(221, 221, 221);
    border-radius: 15px;
    color: #666666;
    text-align: center;
    font-size: 18px;
    font-family: "Roboto", Arial, Helvetica, sans-serif;
    cursor: pointer;
    transition: 0.2s ease-in;
    margin-left: 10px;
  }

  .container-box-donation-type {
    display: flex;
  }
}

/* Mobile Screen */
@media screen and (max-width: 425px) {
  .box-donation-type {
    margin-bottom: 5;
  }
  .container-amount {
    margin: 10px 20px 10px -5px;
  }

  .form-input {
    margin-bottom: 5;
  }

  .btn-submit-donate {
    width: 50%;
  }

  .amount {
    width: 140px;
    height: 120px;
    border: 1px solid rgb(221, 221, 221);
    border-radius: 15px;
    color: #666666;
    text-align: center;
    font-size: 18px;
    font-family: "Roboto", Arial, Helvetica, sans-serif;
    cursor: pointer;
    transition: 0.2s ease-in;
    margin-bottom: 10px;
  }

}

@media screen and (max-width: 376px) {
  .container-amount {
    margin: 10px 20px 10px 0px;
  }

  .amount {
    width: 120px;
    height: 105px;
    border: 1px solid rgb(221, 221, 221);
    border-radius: 15px;
    color: #666666;
    text-align: center;
    font-size: 18px;
    font-family: "Roboto", Arial, Helvetica, sans-serif;
    cursor: pointer;
    transition: 0.2s ease-in;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 361px) {
  .container-amount {
    margin: 10px 20px 10px -20px;
  }

  .amount {
    width: 125px;
    height: 105px;
    border: 1px solid rgb(221, 221, 221);
    border-radius: 15px;
    color: #666666;
    text-align: center;
    font-size: 18px;
    font-family: "Roboto", Arial, Helvetica, sans-serif;
    cursor: pointer;
    transition: 0.2s ease-in;
    margin-bottom: 10px;
  }
}


@media screen and (max-width: 767px) {
  .box-donation-type {
    margin-bottom: 5;
    width: 100%;
  }
  .container-box-donation-type {
    flex-direction: column;
  }

  .container-amount {
    margin: 10px 20px 10px 0px;
  }

  .amount-display {
    padding-left: 25%;
  }
}

@media screen and (min-width: 767px) {
  .donation-info {
    margin: 30px 110px;
  }

  .btn-submit-donate {
    width: 20%;
  }

  .amount-display {
    padding-left: 12%;
  }
}

.title-content {
  font-family: Roboto, Arial, Helvetica, sans-serif;
  color: "#ffffff";
  font-size: 20px;
}

.title-content h1 ul li {
  font-family: Roboto, Arial, Helvetica, sans-serif;
  color: "#ffffff";
  font-size: 16px;
}

/* Large Screen */
@media screen and (min-width: 480px) {
  .content-footer {
    display: flex;
    justify-content: space-between;
  }
  .about-footer {
    width: 35%;
  }
  .content {
    padding: 100px 50px;
  }

  .copyright {
    display: flex;
    justify-content: space-between;
    margin: 10px 65px;
  }

  .border-footer {
    border: 0.5px solid #ffffffbf;
    width: 90%;
    margin: auto;
  }
}

/* Mobile Screen */
@media screen and (max-width: 425px) {
  .content-footer {
    display: block;
  }

  .content {
    padding: 25px 25px;
  }

  .copyright {
    display: flex;
    justify-content: space-around;
    margin-top: 10px;
  }

  .border-footer {
    border: 0.5px solid #ffffffbf;
    width: 90%;
    margin: auto;
  }
}

@media screen and (max-width: 376px) {
  .content-footer {
    display: block;
  }

  .content {
    padding: 25px 25px;
  }

  .copyright {
    display: flex;
    justify-content: space-around;
    margin-top: 10px;
  }

  .border-footer {
    border: 0.5px solid #ffffffbf;
    width: 90%;
    margin: auto;
  }
}

@media screen and (max-width: 361px) {
  .content-footer {
    display: block;
  }

  .content {
    padding: 25px 25px;
  }

  .copyright {
    display: flex;
    justify-content: space-around;
    margin-top: 10px;
  }

  .border-footer {
    border: 0.5px solid #ffffffbf;
    width: 90%;
    margin: auto;
  }
}


.donation-thanks {
  width: 85%;
  margin: 0 auto;
  margin-top: 35px;
  border: 2px solid #ddd;
}

.message-donation-thanks {
  font-size: 18px;
  font-family: "Roboto";
  color: #666666;
}

.donation-thanks .title-donation-thanks {
  font-size: 24px;
  font-family: "Roboto";
  color: #1d1e23;
}

.title-donation-thanks {
  margin-bottom: 15px;
}

/* // Large Size */
@media screen and (min-width: 480px) {
  .donation-thanks {
    margin-top: 10px;
  }
}

/* Mobile Size */
@media screen and (max-width: 480px) {
}

