.frequency-container :focus {
  border-color: #84cfca;
}

.amount:hover {
  box-shadow: 0 2px 10px 0 rgb(0 0 0 / 25%);
  transform: translateY(-5px);
  transition: 0.2s ease-in;
}

.amount:focus {
  box-shadow: 0 3px 3px 0 rgb(0 0 0 / 25%);
  transition: 0.2s ease-in;
}

.form-input:focus {
  border: 1px solid #ced4da;
}

.option-donate {
  font-family: "Roboto", Arial, Helvetica, sans-serif;
}

.dropdown {
  max-height: 150px;
  width: 100%;
  overflow-y: scroll;
}

.dropdown-no-match {
  height: fit-content;
  width: 100%;
}
/* Large Screen */
@media screen and (min-width: 480px) {
  .container-amount {
    margin: 30px 110px;
  }
  .amount {
    width: 85%;
    height: 120px;
    border: 1px solid rgb(221, 221, 221);
    border-radius: 15px;
    color: #666666;
    text-align: center;
    font-size: 18px;
    font-family: "Roboto", Arial, Helvetica, sans-serif;
    cursor: pointer;
    transition: 0.2s ease-in;
    margin-left: 10px;
  }

  .container-box-donation-type {
    display: flex;
  }
}

/* Mobile Screen */
@media screen and (max-width: 425px) {
  .box-donation-type {
    margin-bottom: 5;
  }
  .container-amount {
    margin: 10px 20px 10px -5px;
  }

  .form-input {
    margin-bottom: 5;
  }

  .btn-submit-donate {
    width: 50%;
  }

  .amount {
    width: 140px;
    height: 120px;
    border: 1px solid rgb(221, 221, 221);
    border-radius: 15px;
    color: #666666;
    text-align: center;
    font-size: 18px;
    font-family: "Roboto", Arial, Helvetica, sans-serif;
    cursor: pointer;
    transition: 0.2s ease-in;
    margin-bottom: 10px;
  }

}

@media screen and (max-width: 376px) {
  .container-amount {
    margin: 10px 20px 10px 0px;
  }

  .amount {
    width: 120px;
    height: 105px;
    border: 1px solid rgb(221, 221, 221);
    border-radius: 15px;
    color: #666666;
    text-align: center;
    font-size: 18px;
    font-family: "Roboto", Arial, Helvetica, sans-serif;
    cursor: pointer;
    transition: 0.2s ease-in;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 361px) {
  .container-amount {
    margin: 10px 20px 10px -20px;
  }

  .amount {
    width: 125px;
    height: 105px;
    border: 1px solid rgb(221, 221, 221);
    border-radius: 15px;
    color: #666666;
    text-align: center;
    font-size: 18px;
    font-family: "Roboto", Arial, Helvetica, sans-serif;
    cursor: pointer;
    transition: 0.2s ease-in;
    margin-bottom: 10px;
  }
}


@media screen and (max-width: 767px) {
  .box-donation-type {
    margin-bottom: 5;
    width: 100%;
  }
  .container-box-donation-type {
    flex-direction: column;
  }

  .container-amount {
    margin: 10px 20px 10px 0px;
  }

  .amount-display {
    padding-left: 25%;
  }
}

@media screen and (min-width: 767px) {
  .donation-info {
    margin: 30px 110px;
  }

  .btn-submit-donate {
    width: 20%;
  }

  .amount-display {
    padding-left: 12%;
  }
}