.donation-thanks {
  width: 85%;
  margin: 0 auto;
  margin-top: 35px;
  border: 2px solid #ddd;
}

.message-donation-thanks {
  font-size: 18px;
  font-family: "Roboto";
  color: #666666;
}

.donation-thanks .title-donation-thanks {
  font-size: 24px;
  font-family: "Roboto";
  color: #1d1e23;
}

.title-donation-thanks {
  margin-bottom: 15px;
}

/* // Large Size */
@media screen and (min-width: 480px) {
  .donation-thanks {
    margin-top: 10px;
  }
}

/* Mobile Size */
@media screen and (max-width: 480px) {
}
